<template>
  <div>
    <van-loading type="spinner" v-if="(saleDetailsList.length === 0 && isSaleDetailsLoading) || isSettleDetailsLoading" />
      <van-nav-bar
        :title="$t('settle.settleDetail')"
        left-text=""
        left-arrow
        :fixed="true"
        @click-left="handleBackClick"
      />
      <div class="wrapper">
        <SettleCard :settle-item="saleDetails"
          :can-navigate="false"
        />
        <div class="desc">
          <div class="row">
            <div class="field">{{ $t("settle.settleNum") }}</div>
            <div class="info">{{ saleDetails.orderNo }}</div>
          </div>
          <div class="row">
            <div class="field">{{ $t("settle.recipient") }}</div>
            <div class="info">{{ saleDetails.name }}</div>
          </div>
          <div class="row">
            <div class="field">{{ $t("settle.receiveAccount") }}</div>
            <div class="info">{{ saleDetails.bankCardNo }}</div>
          </div>
          <div class="row">
            <div class="field">{{ $t("settle.receiveBank") }}</div>
            <div class="info">{{ saleDetails.bankName }}</div>
          </div>
          <div class="row">
            <div class="field">{{ $t("settle.postScript") }}</div>
            <div class="info">{{ saleDetails.postscript }}</div>
          </div>
          <!-- 完成时间   订单结算成功才会有 -->
          <div class="row" v-if="saleDetails.orderStatus === 'S'">
            <div class="field">{{ $t("settle.finishTime") }}</div>
            <div class="info">{{ saleDetails.completeTime }}</div>
          </div>
          <div class="row">
            <div class="field">{{ $t("settle.remark") }}</div>
            <div class="info">{{ saleDetails.remarks }}</div>
          </div>
        </div>
        <div class="title">{{ $t("settle.saleDetails") }}</div>
        <van-list
          v-if="saleDetailsList.length !== 0"        
          v-model="isSaleDetailsLoading"              
          :finished="isSaleDetailsFinished"
          :finished-text='$t("mine.nomore")'
          :immediate-check="true"
          :loading-text='$t("settle.loading")'
          @load="handleSaleDetailsLoad"
          class="sale-list"
          offset="40"
          >
          <template v-for="(item, index) in saleDetailsList">
            <div class="sale-item">
              <div class="row">
                <div class="field">{{ $t("settle.date") }}</div>
                <div>{{ item.saleDate }}</div>
              </div>
              <div class="row">
                <div class="field">{{ $t("settle.retailer") }}</div>
                <div>{{ item.retailerName }}</div>
              </div>
              <div class="row">
                <div class="field">{{ $t("settle.saleCount") }}</div>
                <div>{{ item.saleNumber + "  台  |  " + item.mobileModel + "  " + item.mobileConfiguration }}</div>
              </div>
              <div class="row">
                <div class="field">{{ $t("settle.incentive") }}</div>
                <div class="money">{{ (item.saleRewardsAmount && item.saleRewardsAmount.toFixed(2)) + " PYG" }}</div>
              </div>
            </div>
          </template>
        </van-list>
        <template v-else>
          <MyEmpty/>
        </template>
      </div>
  </div>
</template>

<script>
import { getSaleDetailsList, getSettleDetails } from '@/network/api'
import SettleCard from '../components/SettleCard.vue';
import MyEmpty from '@components/MyEmpty.vue'
export default {
  components: {
    SettleCard,
    MyEmpty
  },
  data() {  
    return {
      isSettleDetailsLoading: false,
      saleDetails: {
        createTime: '',
        saleNumber: 0,
        amount: '0.00',
        orderStatus: 'S',
        orderNo: '',
        name: '',
        bankCardNo: '',
        bankName: '',
        postscript:'',
        remarks: ''
      },
      saleDetailsList: [],
      isSaleDetailsLoading: false,
      isSaleDetailsFinished: false,
      saleDetailsTotal: 0,
      saleDetailParams: {
        page: 1,
        pageSize: 10,
      }
    }
  },
  mounted() {
    this.fetchSaleDetailsData()
    this.fetchSettleDetail()
  },
  methods: {
    handleBackClick() {
      this.$router.back(-1);
    },
    // 获取结算详情
    fetchSettleDetail(orderNo = this.$route.params.id) {
      this.isSettleDetailsLoading = true
      getSettleDetails({ orderNo }).then((res) => {
        this.isSettleDetailsLoading = false
        if(res.code === '000000') {
          this.saleDetails = res.data
          console.log("saleDetails", this.saleDetails)
        }else {
          this.$toast(res.message || this.$t("message.normalError"))
        }
      }).catch((err) => {
        this.isSettleDetailsLoading = false
        console.log(err)
      })
    },
    handleSaleDetailsLoad() {
      if(this.saleDetailsList.length === this.saleDetailsTotal) {
        this.isSaleDetailsFinished = true
        return
      }
      this.saleDetailParams.page++
      this.fetchSaleDetailsData({ ...this.saleDetailParams, orderNo: this.$route.params.id })
    },
    // 获取销售明细列表
    fetchSaleDetailsData(params = { page: 1, pageSize: 10, orderNo: this.$route.params.id}) {
      this.isSaleDetailsLoading = true
      getSaleDetailsList(params)
        .then((res) => {
          this.isSaleDetailsLoading = false
          if(res.code === "000000") {
            const { records = [], total = 0 } = res.data
            this.saleDetailsList.push(...records)
            this.saleDetailsTotal = total
            if(total === records.length) {
              this.isSaleDetailsFinished = true
            }
          }else {
            this.$toast( res.message || this.$t("message.normalError"))
          }
          
        }).catch((err) => {
          this.isSaleDetailsLoading = false
          console.log(err)
        })
      }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-list__loading {
  position: relative;
}

/deep/ .van-list__loading .van-loading {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}


.wrapper {
  margin-top: 60px;
  padding: 0 24px;

  .desc {
    font-size: 14px;
    margin: 30px 0;
    
    .row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 5px;

      .field {
        flex-basis: 100px;
        color: #999;
      }
  
      .info {
        color: #333;
      }
    }
    
  }

  .title {
    height: 30px;
    color: #333;
    border-bottom: 1px solid #EFF0F2;
  }

  .sale-list {
    margin-top: 20px;
    .sale-item {
      margin-top: 15px;
         
      font-size: 14px;
      padding: 15px 20px;
      border-radius: 8px;
      background-color: #F7F7F8;
      .row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 5px;
        color: #333;

        .field {
          flex-basis: 100px;
        }
    
        .money {
          font-weight: 600;
        }
      }
    }

  }
 }
</style>